import React, { FC } from 'react';
import Card from 'src/components/shared/card';
import { CardContent, CardHeader, useMediaQuery } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import theme from 'src/shared/theme';

interface Props {
  className?: string;
  paymentsTitle: string;
}

const ProviderLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${theme.spacing(2)};
`;

const Logo = styled.div`
  width: 2.5rem;
`;

const Img = styled.img`
  width: 100%;
`;

const Payments: FC<Props> = props => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "main-info" } }) {
          frontmatter {
            paymentsProviders {
              name
              logo {
                publicURL
              }
            }
          }
        }
      }
    `
  );

  const isXS = useMediaQuery(theme.breakpoints.up(`xs`));
  const providers = data.markdownRemark.frontmatter.paymentsProviders;

  return (
    <Card className={props.className}>
      <CardContent className="small">
        <CardHeader
          title={props.paymentsTitle}
          titleTypographyProps={{
            component: `h5`,
            variant: isXS ? `h6` : `h5`,
          }}
        />
        <ProviderLogos>
          {providers.map(provider => (
            <Logo key={provider.name}>
              <Img src={provider.logo.publicURL} alt={provider.name} />
            </Logo>
          ))}
        </ProviderLogos>
      </CardContent>
    </Card>
  );
};

export default Payments;
