import { css } from 'styled-components';

export default function centerCropImage() {
  return css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  `;
}
