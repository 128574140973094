import { CzechHolidays } from 'czech-holidays';
import { isWeekend } from 'date-fns';

export function isDateHoliday(date: Date) {
  const holidays = CzechHolidays(date.getFullYear());
  const isHolidayDate = holidays.some(
    ({ d: day, m: month }) =>
      day === date.getDate() && month === date.getMonth() + 1
  );

  return isHolidayDate;
}

// TODO: rework to inputs like "7:30"
const workingHours = {
  workingDays: [[7, 19]],
  freeDays: [
    [7, 8],
    [13, 15],
  ],
};

export function isInWorkingHours(now = new Date()) {
  const currentTime = now.toLocaleString([], {
    hour: `numeric`,
    minute: `numeric`,
    timeZone: `Europe/Prague`,
  });

  const [hour, minute] = currentTime.split(`:`);
  const currentHour = +hour + +minute / 60;
  const workingHoursForToday =
    isDateHoliday(now) || isWeekend(now)
      ? workingHours.freeDays
      : workingHours.workingDays;

  return workingHoursForToday.some(
    freeDay => freeDay[0] <= currentHour && currentHour <= freeDay[1]
  );
}
