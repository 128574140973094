import React from 'react';
import styled from 'styled-components';
import Add from '@material-ui/icons/Add';
import SquareIconTitle from 'components/shared/square-icon-title';
import { Typography, CardContent } from '@material-ui/core';
import { SM, XS } from 'src/shared/media-query';
import PhoneLink from 'components/shared/phone-link';
import Card from 'components/shared/card';
import theme from 'src/shared/theme';
import StandardWorkingHours from 'components/shared/standard-working-hours';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface Props {
  emergencyHoursCaption: string;
  emergencyDescription: string;
  emergencyPhoneCaption: string;
  emergencyPhoneNumber: string;
  className?: string;
}

const Container = styled(Card)`
  position: relative;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
`;

const BoldText = styled(Typography)`
  font-weight: bold;
  color: inherit;

  @media ${SM} {
    font-size: 1.25rem;
  }
`;

const EmergencyDescription = styled(Typography)`
  margin-bottom: ${theme.spacing(4)};
  color: inherit;

  @media ${SM} {
    margin-bottom: ${theme.spacing(6)};
    font-size: 1.25rem;
  }
`;

const EmergencyHoursContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(4)};
`;

const Hr = styled.hr`
  position: absolute;
  width: 100%;
  left: 0;
  border: 1px solid ${theme.palette.common.white};
  opacity: 0.2;
  margin: 0;
`;

const Phone = styled(PhoneLink)`
  text-decoration: underline;
  font-weight: bold;
  line-height: 1.5;
  color: ${theme.palette.text.primary};
  font-size: 1.1rem;

  @media ${XS} {
    font-size: 1.5rem;
  }
`;

const WorkingHours = React.forwardRef((props: Props, ref) => {
  const isBetweenSMandMD = useMediaQuery(theme.breakpoints.between(`sm`, `md`));

  return (
    <Container className={props.className} ref={ref}>
      <CardContent>
        <StandardWorkingHours
          typographyVariant={isBetweenSMandMD ? `subtitle1` : `h5`}
        />
      </CardContent>

      <Hr />

      <CardContent>
        <SquareIconTitle Icon={Add} color={theme.palette.warning.main}>
          {props.emergencyHoursCaption}
        </SquareIconTitle>
        <EmergencyHoursContent>
          <EmergencyDescription>
            {props.emergencyDescription}
          </EmergencyDescription>
          <BoldText>{props.emergencyPhoneCaption}</BoldText>
          <Phone phone={props.emergencyPhoneNumber} />
        </EmergencyHoursContent>
      </CardContent>
    </Container>
  );
});

export default WorkingHours;
