import styled from 'styled-components';
import React, { FC } from 'react';

interface Props {
  className?: string;
  id?: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SectionContainer: FC<Props> = (props) => (
  <Container id={props.id} className={props.className}>{props.children}</Container>
);

export default SectionContainer;
