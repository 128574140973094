import React, { FC, memo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Card from 'components/shared/card';
import LoopIcon from '@material-ui/icons/Loop';
import { useInView } from 'react-intersection-observer';

interface Props {
  className?: string;
}
const API_KEY = process.env.GATSBY_GOOGLE_API_KEY;
const QUERY = encodeURI(`Spojovací 571 Trutnov`);

const Container = styled(Card)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 170px;
`;

const IFrame = styled.iframe`
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const SpinnerIcon = styled(LoopIcon)`
  animation: ${rotate} 2s linear infinite;
  font-size: 8.5rem;
`;

const GoogleMap: FC<Props> = (props) => {
  const [showSpinner, setShowSpinner] = useState(true);

  function hideSpinner() {
    setShowSpinner(false);
  }

  return (
    <Container className={props.className}>
      {showSpinner ? <SpinnerIcon /> : null}
      <IFrame
        onLoad={hideSpinner}
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/search?key=${API_KEY}&q=${QUERY}`}
      />
    </Container>
  );
};

export default memo(GoogleMap);
