import React from 'react';
import { Card as MuiCard } from '@material-ui/core';

type TypeOfMuiCard = typeof MuiCard;

const Card: TypeOfMuiCard = React.forwardRef((props, ref: any) => (
  <MuiCard {...props} elevation={0} ref={ref as any} />
));

export default Card;
