import React, { FC } from 'react';
import Card from 'src/components/shared/card';
import {
  CardContent,
  CardHeader,
  useMediaQuery,
} from '@material-ui/core';
import ParkingIcon from '@material-ui/icons/LocalParking';
import styled from 'styled-components';
import IconWithBackground from 'src/components/shared/icon-with-background';
import theme from 'src/shared/theme';
import Text from 'components/shared/text';

interface Props {
  className?: string;
  parkingTitle: string;
  parkingNote: string;
}

const ParkingIconSquared = styled(IconWithBackground)`
  border-radius: 0.5rem;
  margin-right: ${theme.spacing(4)};
  display: inline-flex;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Parking: FC<Props> = ({ parkingTitle, parkingNote, ...rest }) => {
  const isXS = useMediaQuery(theme.breakpoints.up(`xs`));

  return (
    <Card {...rest}>
      <CardContent className="small">
        <CardHeader
          title={parkingTitle}
          titleTypographyProps={{
            component: `h5`,
            variant: isXS ? `h6` : `h5`,
          }}
        />
        <Row>
          <ParkingIconSquared Icon={ParkingIcon} />
          <Text>{parkingNote}</Text>
        </Row>
      </CardContent>
    </Card>
  );
};

export default Parking;
