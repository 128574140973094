import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Card from 'components/shared/card';
import { CardContent, CardHeader, Typography } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import { startOfMinute } from 'date-fns';
import centerCropImage from 'src/shared/center-crop-image';
import Text from 'components/shared/text';
import theme from 'src/shared/theme';
import Spinner from 'components/shared/spinner/spinner';
import { isInWorkingHours } from 'src/shared/utils/working-hours';
import { RESERVATIONS } from 'src/shared/urls';

interface Props {
  className?: string;
  webcamTitle: string;
  webcamNote: string;
}

const MAX_IMAGE_WIDTH = `650px`;
const MAX_IMAGE_HEIGHT = `435px`;

const ImageWrapper = styled.div`
  position: relative;
  max-width: ${MAX_IMAGE_WIDTH};
  max-height: ${MAX_IMAGE_HEIGHT};
  width: 100%;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 22.13%
    );
  }
`;

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const Content = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
`;

function stripLinkText(note = `Text <a>link text</a>`) {
  const linkStartsAt = note.indexOf(`<a>`);
  const linkEndsAt = note.indexOf(`</a>`);

  const startingText = note.substring(0, linkStartsAt);
  const link = note.substring(linkStartsAt + 3, linkEndsAt);
  const endingText = note.substring(linkEndsAt + 4);

  // safety first
  if (!startingText || !link || !endingText) {
    return {
      startingText: `Pokud nechcete čekat, `,
      link: `rezervujte si svůj termín návštěvy`,
      endingText: `.`,
    };
  }

  return {
    startingText,
    link,
    endingText,
  };
}

const imgSource = `/.netlify/functions/get-webcam`;
const webcamReloadInterval = 60000;
const getCurrentMinuteTimestamp = () => startOfMinute(new Date()).getTime();
const isTurnedOff = process.env.GATSBY_WEBCAM_OFF === `true`;

const Webcam: FC<Props> = props => {
  const { startingText, link, endingText } = stripLinkText(props.webcamNote);

  const [timestamp, setTimestamp] = useState(getCurrentMinuteTimestamp());
  const [isWorkingHours, setIsWorkingHours] = useState(
    isInWorkingHours(new Date())
  );
  const [image, setImage] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [reloadInterval, setReloadInterval] = useState(webcamReloadInterval);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isInWorkingHours(new Date())) {
        setIsWorkingHours(true);
        setTimestamp(getCurrentMinuteTimestamp());
      } else {
        setIsWorkingHours(false);
      }
    }, webcamReloadInterval);

    return () => clearInterval(timer);
  }, [reloadInterval]);

  useEffect(() => {
    if (!isWorkingHours) {
      return;
    }
    setLoading(true);

    fetch(`${imgSource}?t=${timestamp}`)
      .then(async response => {
        if (response.ok && response.status !== 404 && response.body) {
          const blob = await response.blob();
          const fetchedImage = URL.createObjectURL(blob);
          setReloadInterval(webcamReloadInterval);
          setImage(fetchedImage);
        } else {
          throw new Error(`response not ok`);
        }
      })
      .catch(() => {
        setImage(``);
        setImageLoadError(true);
        setReloadInterval(1000);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [timestamp, isWorkingHours]);

  return (
    <Container className={props.className}>
      <ImageWrapper>
        {image && isWorkingHours && !isTurnedOff ? (
          <Image image={image} />
        ) : (
          <NoImage
            isLoading={isLoading}
            isTurnedOff={isTurnedOff}
            isImageLoadError={imageLoadError}
          />
        )}
      </ImageWrapper>
      <Content>
        <CardHeader
          title={props.webcamTitle}
          titleTypographyProps={{ component: `h5` }}
        />
        <Typography
          variant="body2"
          css={css`
            font-size: 0.875rem;
          `}
        >
          {startingText}
          <Link to={RESERVATIONS}>{link}</Link>
          {endingText}
        </Typography>
      </Content>
    </Container>
  );
};

export default Webcam;

const WebcamNonActive = ({ isTurnedOff, isImageLoadError }) => {
  let text = `Kamera je aktivní pouze v\xA0ordinačních hodinách.`;

  if (isTurnedOff) {
    text = `Náhled do čekárny je dočasně vypnutý.`;
  }

  if (isImageLoadError) {
    text = `Nepodařilo se stáhnout obrázek náhledu.`;
  }

  return (
    <div
      css={css`
        width: 100%;
        height: ${MAX_IMAGE_HEIGHT};
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        padding: ${theme.spacing(8)};
      `}
    >
      <Text variant="h4">{text}</Text>
    </div>
  );
};

const Image = ({ image }: { image: string }) => (
  <>
    <img
      css={css`
        width: 100%;
        display: block;
        ${centerCropImage};
      `}
      src={image}
      alt="Pohled do čekárny"
    />
  </>
);

const NoImage = ({
  isLoading,
  isTurnedOff,
  isImageLoadError,
}: {
  isLoading: boolean;
  isTurnedOff: boolean;
  isImageLoadError: boolean;
}) =>
  isLoading ? (
    <Spinner
      css={css`
        position: relative;
        height: 500px;
      `}
    />
  ) : (
    <WebcamNonActive
      isTurnedOff={isTurnedOff}
      isImageLoadError={isImageLoadError}
    />
  );
