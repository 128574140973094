import GatsbyImage, { FixedObject, FluidObject, GatsbyImageProps } from 'gatsby-image';
import React, { FC } from 'react';

import styled from 'styled-components';

export type FluidObjectWithPresentationWidth = FluidObject & {
  presentationWidth: string;
};

export type GatsbyImgOrSrc =
  | FluidObjectWithPresentationWidth
  | FixedObject
  | string;

interface CustomProps {
  className?: string;
  src?: GatsbyImgOrSrc;
}

export type Props = CustomProps & (Omit<GatsbyImageProps, `fixed`>);

const ClassicImgWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: auto;
`;

const ClassicImg = styled.img<{$clip?: boolean}>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $clip }) => $clip ? `cover` : `fill`};
`;

// TODO: This components shouldn't look like it can substitute classic img,
// at first sight it should look like CMS-preview-only tool
const Img: FC<Props> = (props) => {
  const { src } = props;
  const imgPath = src as string | undefined;
  const fluid = src as FluidObject;
  const fixed = src as FixedObject;

  if (!src) {
    throw new Error(
      `\`src\` param is not set properly for custom Img component. It should be \`string\` (for CMS preview) \`fluid\` or \`fixed\`. Is ${
        src}`,
    );
  }

  const isClassicSrc = typeof src === `string`;
  // This is not very safe. But no other fluid vs fixed indicator was found
  const isFluid = !isClassicSrc && fluid?.sizes != null;
  const isFixed = !isClassicSrc && !isFluid;

  if (isFluid) {
    return <GatsbyImage fluid={fluid} {...(props as GatsbyImageProps)} />;
  } if (isFixed) {
    return <GatsbyImage fixed={fixed} {...(props as GatsbyImageProps)} />;
  }
  return (
    <ClassicImgWrapper {...props}>
      <ClassicImg src={imgPath} $clip={!imgPath?.endsWith(`.svg`)} />
    </ClassicImgWrapper>
  );
};

export default Img;
